
import {defineComponent, onMounted, reactive, ref, toRefs} from 'vue';
import {ElMessage, ElMessageBox} from "element-plus/es";
import {useRouter} from "vue-router";
import {Diff2HtmlUI} from "diff2html/lib-esm/ui/js/diff2html-ui";
import {Diff2HtmlUIConfig} from "diff2html/lib-esm/ui/js/diff2html-ui-base";
import 'diff2html/bundles/css/diff2html.min.css';
import 'diff2html/bundles/js/diff2html-ui.min.js'
import useNoteHistories from "@/composables/useNoteHistories";
import useShowNotePageData from "@/composables/useShowNotePageData";
import {apiResponseErrorHandle} from "@/composables/errorHandler";

export default defineComponent({
  name: 'Index',

  setup() {
    const {getNoteHistories, getNoteHistoryUnifiedDiff, restoreNoteHistory} = useNoteHistories();
    const {parseNoteId} = useShowNotePageData();
    const router = useRouter();

    const state: {
      showDiff: boolean,
      currentHistoryId: number | null,
      tableData: [],
      diffOutputFormat: "line-by-line" | "side-by-side" | undefined,
      diffOutputFormatRadio: "分屏" | "合并",
      diffString: string
    } = reactive({
      showDiff: false,
      currentHistoryId: null,
      tableData: [],
      diffOutputFormat: 'line-by-line',
      diffOutputFormatRadio: "合并",
      diffString: ''
    });
    const id = ref<number | null>(null)

    id.value = parseNoteId();

    onMounted(() => {
      id.value && list(id.value)
    })

    const list = (noteId: number) => {
      getNoteHistories(noteId).then(function (response) {
        state.tableData = response.data.noteHistories;
      }).catch(apiResponseErrorHandle('获取数据失败', () => {
        return
      }));
    }

    const render = () => {
      const targetElement = document.getElementById('myDiffElement');
      if (!targetElement) {
        return;
      }
      const configuration: Diff2HtmlUIConfig = {
        drawFileList: false,
        fileListToggle: false,
        fileListStartVisible: false,
        fileContentToggle: false,
        matching: 'lines',
        diffStyle: 'char',
        outputFormat: state.diffOutputFormat,
        synchronisedScroll: true,
        highlight: true,
        renderNothingWhenEmpty: false,
        stickyFileHeaders: false
      };
      const diff2htmlUi = new Diff2HtmlUI(targetElement, state.diffString, configuration);
      diff2htmlUi.draw();
      diff2htmlUi.highlightCode();
    }

    const viewDiff = (row: any) => {
      state.showDiff = true;
      state.currentHistoryId = row.id;
    }

    const viewDiffClose = (done: () => void) => {
      state.diffString = ''
      done()
    }

    const showDiffOpened = () => {
      replaceMyDiffElement()
      get(id.value, state.currentHistoryId)
    }

    const replaceMyDiffElement = () => {
      const targetElement = document.getElementById('myDiffElement');
      const myDiffElement = document.createElement("div");
      myDiffElement.setAttribute('id', 'myDiffElement');
      targetElement?.replaceWith(myDiffElement);
    }

    const get = (noteId: number | null, historyId: number | null) => {
      noteId && historyId && getNoteHistoryUnifiedDiff(noteId, historyId).then(function (response) {
        state.diffString = response.data.unifiedDiff
        render()
      }).catch(apiResponseErrorHandle('获取数据失败'))
    }

    const goBack = () => {
      router.back();
    }

    const restore = (row: any) => {
      ElMessageBox.confirm(
          '笔记内容将会恢复到此版本，是否继续？',
          '提醒',
          {
            confirmButtonText: '是',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        restoreNoteHistory(id.value, row.id).then(function (response) {
          ElMessage({
            showClose: true,
            message: '版本恢复成功',
            type: 'success',
          });
        }).catch(apiResponseErrorHandle('版本恢复失败'));
      }).catch(() => {return})
    }

    const onFormatClick = async (label: '分屏' | '合并') => {
      if (label === '分屏') {
        state.diffOutputFormat = "side-by-side"
        console.log("click: side-by-side")
      } else {
        state.diffOutputFormat = "line-by-line"
        console.log("click: line-by-line")
      }
      render();
    }

    return {
      ...toRefs(state),
      goBack,
      viewDiff,
      viewDiffClose,
      showDiffOpened,
      restore,
      onFormatClick
    }
  }
});
